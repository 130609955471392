import Choices from "choices.js";
import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = [
    "choices",
    "choices_search",
    "choices_multiple",
    "choices_partner_search",
    "choices_rooms_reload",
    "choices_deal_food_supplier_search",
    "choices_deal_service_city_search",
    "choices_linked_search",
    "choices_groupped_search",
    "choices_groupped_multiple_search",
    "choices_guide",
    "choices_data_guide",
    "choices_ajax_search",
    // data option for translit search
    "choices_data_multiple",
    "choices_data_search",
    "choices_transport_integration_supplier"
  ]

  initialize() {
    //
    // data option for translit search
    //
    // choices_data_multiple
    if (this.hasChoices_data_multipleTarget) { this.choicesData(this.choices_data_multipleTarget, true); return; }
    if (this.hasChoices_data_searchTarget) { this.choicesData(this.choices_data_searchTarget, false); return; }
    if (this.hasChoices_data_guideTarget) { this.dataChoicesGuide(); return; }

    // choices
    if (this.hasChoicesTarget) { this.initChoices(); return; }
    // choices rooms reload
    if (this.hasChoices_rooms_reloadTarget) { this.roomsReload(this.choices_rooms_reloadTarget); return; }
    // choices_search
    if (this.hasChoices_searchTarget) { this.choicesSearch();; return; }
    // choices_multiple
    if (this.hasChoices_multipleTarget) { this.choicesMultiple(); return; }
    // DEAL FORMS
    // choices_linked_search
    if (this.hasChoices_linked_searchTarget) { this.linkedSearch(); return; }
    // choices_partner_search
    if (this.hasChoices_partner_searchTarget) { this.partnerSearch(); return; }
    // choices_ajax_search
    if (this.hasChoices_ajax_searchTarget) { this.ajaxSearch(); return; }
    // choices_deal_service_city_search
    if (this.hasChoices_deal_service_city_searchTarget) { this.dealServiceCitySearch(); return; }
    // deal services foods
    if (this.hasChoices_deal_food_supplier_searchTarget) { this.dealFoodSupplierSearch(); return; }
    // choices_groupped_search
    if (this.hasChoices_groupped_searchTarget) { this.grouppedSearch(); return; }
    // choices_groupped_multiple_search
    if (this.hasChoices_groupped_multiple_searchTarget) { this.grouppedMultipleSearch(); return; }
    // choices_guide
    if (this.hasChoices_guideTarget) { this.choicesGuide(); return; }
    // choices_transport_integration_supplier
    if (this.hasChoices_transport_integration_supplierTarget) { this.setupSupplierIntegrationSearch(); return; }
  }

  //
  // init chooses
  //
  initChoices() {
    this.choicesTarget.choices = new Choices(this.choicesTarget, {
      shouldSort: false,
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.addValidateTrigger(this.choicesTarget, this.choicesTarget.choices)
    this.setItemsWidthToMaxWidth(this.choicesTarget)
  }

  roomsReload(target) {
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);
    let choices = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      placeholder: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    target.choices = choices

    let locale = super.appLocale;
    let form = target.closest('form')
    let controller = this.application.getControllerForElementAndIdentifier(form, "layout--form")
    choices.passedElement.element.addEventListener(
      "change",
      function (event) {
        let el = choices.passedElement.element
        if (el.getAttribute("data-need-reloaded") == "true") {
          choices.clearInput();
          choices.clearStore();
          choices.clearChoices();
          choices.setChoiceByValue(null);

          choices.setChoices(async () => {
            try {
              const url = "/" + locale + "/hotel_room_types"
              const items = await fetch(url);
              return items.json();
            } catch (err) {
              // console.error(err);
            }
          }, 'value', 'label', true);

          el.setAttribute('data-need-reloaded', false);
          // choices.setChoiceByValue(el.getAttribute("data-reloaded-id"))
          let fieldContainer;
          if (form != undefined && controller != undefined)
            fieldContainer = el.closest('.form-group');
          if (controller != undefined && fieldContainer != undefined) {
            controller.checkChoicesMulti(fieldContainer);
          }
          choices.showDropdown();
        }
      },
      false,
    );
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  choicesSearch() {
    this.choices_searchTarget.choices = new Choices(this.choices_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["label"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    this.addValidateTrigger(this.choices_searchTarget, this.choices_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_searchTarget)
  }

  //// DATA ////
  choicesData(target, multiple_select) {
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);
    let choice = new Choices(target, {
      placeholder: true,
      removeItemButton: multiple_select,
      removeItems: multiple_select,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    })
    target.choices = choice;

    /// linked choises reload ///
    if (target.getAttribute("data-linked-target")) {
      let reload = this.reloadLinkedChoice;
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          reload(this, event.detail.value);
        },
        false,
      );
    }
    /// END linked choises reload ///

    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  dataChoicesGuide() {
    const target = this.choices_data_guideTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      shouldSort: false,
      shouldSortItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            // console.log(data);
            let settings = {}, img_tag = "", languages = ""
            try {
              settings = data.customProperties ? data.customProperties : {}
            } catch {
              settings = {}
            }

            if (settings.image_url) img_tag = "<img src='" + settings.image_url + "' class='img-circle img-size-36' ></img>"
            if (settings.languages) languages = settings.languages

            return template(`
              <div class="guide_select ${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
              } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
              }>

                <div class="media align-items-center">
                  <div class="mr-3">${img_tag}</div>
                  <div class="media-body">
                    <div>${data.label}</div>
                    <span class="text-muted font-size-11">${languages}</span>
                  </div>
                </div>
              </div>
            `);
          }
        }
      }
    });

    target.choices = choice;
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)

    // disable if is-disabled == true
    let isDisable = target.getAttribute("data-is-disabled")
    if (isDisable == "true") {
      choice.disable();
    }
  }
  //// DATA ////

  choicesMultiple() {
    this.choices_multipleTarget.choices = new Choices(this.choices_multipleTarget, {
      shouldSort: false,
      placeholder: true,
      removeItemButton: true,
      removeItems: true,
      searchEnabled: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.addValidateTrigger(this.choices_multipleTarget, this.choices_multipleTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_multipleTarget)
  }

  linkedSearch() {
    let reload = this.reloadLinkedChoice;
    let choice = new Choices(this.choices_linked_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    this.choices_linked_searchTarget.choices = choice;

    if (this.choices_linked_searchTarget.getAttribute("data-linked-target")) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          reload(this, event.detail.value);
        },
        false,
      );
    }
    this.addValidateTrigger(this.choices_linked_searchTarget, this.choices_linked_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_linked_searchTarget)
  }

  partnerSearch() {
    let reload_contacts = this.reloadPartnerContacts
    let reload_partners = this.getPartnersData
    let choice = new Choices(this.choices_partner_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    this.choices_partner_searchTarget.choices = choice;

    reload_partners(this.choices_partner_searchTarget, null, true);

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_partners(this, event.detail.value, false);
      },
      false,
    );

    if (this.choices_partner_searchTarget.getAttribute("data-contact-target")) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          reload_contacts(
            this,
            event.detail.value,
            event.target.firstChild.getAttribute("data-custom-properties") == "LegalPartner");
        },
        false,
      );
    }

    if (this.choices_partner_searchTarget.getAttribute("data-contact-target")) {
      choice.passedElement.element.addEventListener(
        "reload_contacts",
        function (event) {
          reload_contacts(
            this,
            this.choices.getValue(true),
            true);
        },
        false,
      );
    }

    this.addValidateTrigger(this.choices_partner_searchTarget, this.choices_partner_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_partner_searchTarget)
  }

  ajaxSearch() {
    let reload_datas = this.getAjaxSearchData
    let is_multiple = this.choices_ajax_searchTarget.classList.contains("choices-multiple")
    let choice = new Choices(this.choices_ajax_searchTarget, {
      removeItemButton: is_multiple,
      removeItems: is_multiple,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      duplicateItemsAllowed: false,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    this.choices_ajax_searchTarget.choices = choice;

    reload_datas(this.choices_ajax_searchTarget, null, true);

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_datas(this, event.detail.value, false);
      },
      false,
    );

    this.addValidateTrigger(this.choices_ajax_searchTarget, this.choices_ajax_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_ajax_searchTarget)
  }

  dealServiceCitySearch() {
    let choice = new Choices(this.choices_deal_service_city_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    this.choices_deal_service_city_searchTarget.choices = choice;
    let enable_target_id = this.choices_deal_service_city_searchTarget.getAttribute("data-enable-target-id")

    if (enable_target_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const enable_input = document.getElementById(enable_target_id)
          if (enable_input) enable_input.disabled = false
        },
        false,
      );
    }

    this.addValidateTrigger(this.choices_deal_service_city_searchTarget, this.choices_deal_service_city_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_deal_service_city_searchTarget)
  }

  dealFoodSupplierSearch() {
    let reload_suppliers = this.getSuppliersData
    let choice = new Choices(this.choices_deal_food_supplier_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    this.choices_deal_food_supplier_searchTarget.choices = choice;

    reload_suppliers(this.choices_deal_food_supplier_searchTarget, null, true);

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_suppliers(this, event.detail.value, false);
      },
      false,
    );

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        // console.log(event.detail.value);
      },
      false,
    );

    this.addValidateTrigger(this.choices_deal_food_supplier_searchTarget, this.choices_deal_food_supplier_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_deal_food_supplier_searchTarget)
  }

  grouppedSearch() {
    let choice = new Choices(this.choices_groupped_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      shouldSort: false,
      shouldSortItems: false,
      searchEnabled: false,
      searchFields: ["label"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            let settings = data.customProperties ? JSON.parse(data.customProperties) : {}
            return template(`
                <div class="${settings.is_parent ? 'choices__group' :
                classNames.item + ' ' + classNames.itemChoice + ' ' + (data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + (settings.is_child ? " choices_is_child" : " ")
                + (data.selected ? " is-selected " : '')} "

                ${settings.is_parent ? 'data-group ' : "data-select-text='" + this.config.itemSelectText + "' data-choice " +
                (data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable')}
                data-id="${data.id}" data-value="${settings.is_parent ? data.label : data.value}"
                role=${settings.is_parent ? "group" : "option"}>

                ${settings.is_parent ? '<div class="choices__heading">' + data.label + '</div>' : data.label}
                </div>
            `);
          },
        }
      }
    });

    this.choices_groupped_searchTarget.choices = choice;

    this.addValidateTrigger(this.choices_groupped_searchTarget, this.choices_groupped_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_groupped_searchTarget)
  }

  grouppedMultipleSearch() {
    let choice = new Choices(this.choices_groupped_multiple_searchTarget, {
      placeholder: true,
      shouldSort: false,
      shouldSortItems: false,
      removeItemButton: true,
      removeItems: true,
      searchEnabled: false,
      searchChoices: false,
      position: "bottom",
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        this.input.element.setAttribute("readonly", "readonly");
      },
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            let settings = data.customProperties ? JSON.parse(data.customProperties) : {}
            let hidden_groups = this.passedElement.element.parentNode.parentNode.querySelector(".choices__list.choices__list--dropdown");
            hidden_groups = hidden_groups.getAttribute("data-hidden_groups")
            hidden_groups = hidden_groups ? hidden_groups.split(";") : [];

            return template(`
                <div class="${settings.is_parent ? 'choices__group' :
                classNames.item + ' ' + classNames.itemChoice + ' ' + (data.disabled ? classNames.itemDisabled : classNames.itemSelectable) + (settings.is_child ? " choices_is_child" : " ")} "
                ${settings.is_parent ? 'data-group ' : "data-select-text='" + this.config.itemSelectText + "' data-choice " +
                (data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable')}
                data-id="${data.id}" data-value="${settings.is_parent ? data.label : data.value}"
                ${hidden_groups && hidden_groups.includes(settings.group_id) ? ' hidden ' : ''}
                ${settings.is_parent || settings.is_child ? " data-group-id= " + settings.group_id : ''}

                role=${settings.is_parent ? "group" : settings.is_child ? "treeitem" : "option"}>

                ${settings.is_parent ? '<div class="choices__heading">' + data.label + '</div>' : data.label}
                </div>
            `);
          },
          item: (classNames, data) => {
            return template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}
               ${data.placeholder ? classNames.placeholder : ''}"
              data-item data-id="${data.id}" data-value="${data.value}"
              ${data.active ? 'aria-selected="true"' : ''}
              ${data.disabled ? 'aria-disabled="true"' : ''}>
              ${data.label}<button type="button" class="choices__button" aria-label="Remove item: '${data.value}'" data-button="">Remove item</button>
              </div>
            `);
          }
        }
      }
    });

    this.choices_groupped_multiple_searchTarget.choices = choice;

    choice.passedElement.element.addEventListener(
      "addItem",
      function (event) {
        let settings = event.detail.customProperties ? JSON.parse(event.detail.customProperties) : {}
        if (settings.is_child) {
          let select_target = event.target;
          let choices_target = select_target.closest(".choices")
          let choices_list_target = choices_target.querySelector(".choices__list.choices__list--dropdown")
          const childs = choices_list_target.querySelectorAll("[data-group-id='" + settings.group_id + "'].choices_is_child")
          if (childs.length < 1) {
            let hidden_groups = choices_list_target.getAttribute("data-hidden_groups");
            choices_list_target.setAttribute("data-hidden_groups", (hidden_groups ? hidden_groups + ";" : "") + settings.group_id);
          }
        }
      },
      false,
    );

    choice.passedElement.element.addEventListener(
      "removeItem",
      function (event) {
        let select_target = event.target;
        let choices_target = select_target.closest(".choices")
        let choices_list_target = choices_target.querySelector(".choices__list.choices__list--dropdown")
        let item = choices_list_target.querySelector(".choices__list [data-value='" + event.detail.value + "']");

        if (item) {
          let group_id = item.getAttribute("data-group-id");

          if (group_id) {
            let hidden_groups = choices_list_target.getAttribute("data-hidden_groups");
            hidden_groups = hidden_groups ? hidden_groups.split(";") : [];
            let index = hidden_groups.indexOf(group_id);
            if (index !== -1) {
              hidden_groups.splice(index, 1);
              choices_list_target.setAttribute("data-hidden_groups", (hidden_groups ? hidden_groups.join(";") : ""));
            }
            // find parent item and remove hidden
            let parent_item = choices_list_target.querySelector(".choices__list [data-group-id='" + group_id + "'].choices__group");
            if (parent_item) parent_item.removeAttribute("hidden");
            item.removeAttribute("hidden");
          }
        }
      },
      false,
    );

    this.addValidateTrigger(this.choices_groupped_multiple_searchTarget, this.choices_groupped_multiple_searchTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_groupped_multiple_searchTarget)
  }

  //
  choicesGuide() {
    let choice = new Choices(this.choices_guideTarget, {
      removeItemButton: false,
      removeItems: false,
      shouldSort: false,
      shouldSortItems: false,
      searchEnabled: true,
      searchFields: ["label"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            let settings = {}, img_tag = "", languages = ""
            try {
              settings = data.customProperties ? JSON.parse(data.customProperties) : {}
            } catch {
              settings = {}
            }

            if (settings.image_url) img_tag = "<img src='" + settings.image_url + "' class='img-circle img-size-36' ></img>"
            if (settings.languages) languages = settings.languages

            return template(`
              <div class="guide_select ${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
              } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
              }>

                <div class="media align-items-center">
                  <div class="mr-3">${img_tag}</div>
                  <div class="media-body">
                    <div>${data.label}</div>
                    <span class="text-muted font-size-11">${languages}</span>
                  </div>
                </div>
              </div>
            `);
          }
        }
      }
    });

    this.choices_guideTarget.choices = choice;

    this.addValidateTrigger(this.choices_guideTarget, this.choices_guideTarget.choices)

    // disable if is-disabled == true
    let isDisable = this.choices_guideTarget.getAttribute("data-is-disabled")
    if (isDisable == "true") {
      choice.disable();
    }
  }


  //
  // private
  //
  async getAjaxSearchData(target, q, on_init = false) {
    let url = target.getAttribute("data-url");
    let current_id = target.getAttribute("data-current-id");
    let choice = target.choices;

    if (on_init && current_id != null) {
      url += "&current_id=" + current_id
    } else if (q != null) {
      url += "&q=" + q
    }
    await fetch(url)
      .then(res => res.json())
      .then((data) => {

        choice.setChoices(data, 'value', 'label', true)

        if (on_init && current_id != null) {
          choice.setChoiceByValue(current_id.split(","));
        }
      });
  }

  async getPartnersData(target, q, on_init = false) {
    let partner_url = target.getAttribute("data-partner-url");
    let partner_id = target.getAttribute("data-partner-current-id");
    let partner_choice = target.choices;
    let url = partner_url;

    if (on_init && partner_id != null) {
      url += "?partner_id=" + partner_id
    } else if (q != null) {
      url += "?q=" + q
    }
    await fetch(url)
      .then(res => res.json())
      .then((data) => {

        partner_choice.setChoices(data, 'value', 'label', true)

        if (on_init && partner_id != null) {
          partner_choice.setChoiceByValue(partner_id);
        }
      });
  }

  async getSuppliersData(target, q) {
    let url = target.getAttribute("data-url");
    let city_id = document.getElementById("deal_services_add_new_city_id").value
    let service_type = target.getAttribute("data-service-type");
    let choice = target.choices;

    if (city_id && service_type) {
      url += "?city_id=" + city_id + "&service_type=" + service_type + "&q=" + q
    }
    await fetch(url)
      .then(res => res.json())
      .then((data) => {
        choice.setChoices(data, 'value', 'label', true)
      });
  }

  async reloadPartnerContacts(target, partner_id, is_legal) {
    let contact_field_id = target.getAttribute("data-contact-target");
    let contact_url = target.getAttribute("data-contact-url");
    let contact_field = document.querySelector(contact_field_id);
    let contact_form_group = contact_field.closest(".form-group");
    let contact_form_label_text = contact_form_group.querySelector("label span.text-danger");
    let contact_add_button = contact_form_group.querySelector(".row a.btn-link");

    if (contact_field) {
      let contact_choice = contact_field.choices;

      contact_choice.clearInput();
      contact_choice.clearStore();
      contact_choice.clearChoices();
      contact_choice.setChoiceByValue("");

      if (!is_legal) {
        contact_choice.disable();
        contact_form_label_text.innerHTML = "";
        if (contact_add_button) {
          contact_add_button.classList.add("disabled");
          contact_add_button.setAttribute("partner-id", "");
        }
      } else {
        contact_choice.enable();
        contact_form_label_text.innerHTML = "*";
        if (contact_add_button) {
          contact_add_button.classList.remove("disabled");
          contact_add_button.setAttribute("partner-id", partner_id);
        }

        await fetch(contact_url + "?partner_id=" + partner_id)
          .then(res => res.json())
          .then((data) => {
            contact_choice.setChoices(data, 'value', 'label', true)

            if (data.length) {
              contact_choice.setChoiceByValue(data[0]["value"]);
            }
          });
      }
    }
  }

  reloadLinkedChoice(target, selected_id) {
    let linked_field_id = target.getAttribute("data-linked-target");
    let linked_url = target.getAttribute("data-linked-url");
    let linked_key = target.getAttribute("data-linked-key") || "id";
    let linked_field = document.querySelector(linked_field_id);

    if (linked_field) {
      let linked_choice = linked_field.choices;
      linked_choice.clearInput();
      linked_choice.clearStore();

      linked_choice.setChoices(async () => {
        try {
          const items = await fetch(linked_url + "?" + linked_key + "=" + selected_id);
          return items.json();
        } catch (err) {
          // console.error(err);
        }
      }, 'value', 'label', true);

      linked_choice.showDropdown();
    }
  }

  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  setItemsWidthToMaxWidth(html_element) {
    const choose_div = html_element.closest(".choices")
    let choices_object = html_element.choices

    choices_object.passedElement.element.addEventListener(
      'showDropdown',
      function (event) {
        const choices_dropdown = choose_div.querySelector(".choices__list--dropdown")
        const items = choices_dropdown.getElementsByClassName('choices__item--selectable')
        if (items.length > 0) {
          const widths = [].slice.call(items).map(function (div) { return div.getBoundingClientRect().width; });
          const maxWidth = Math.max.apply(null, widths);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.style.width = maxWidth + "px";
          }
        }
      },
      false,
    );
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  setupSupplierIntegrationSearch() {
    // Initialize the choices dropdown
    this.choicesData(this.choices_transport_integration_supplierTarget, false);
    let toggleStatusField = this.toggleSupplierIntegrationStatusField;

    try {
      const supplierSelect = this.choices_transport_integration_supplierTarget.choices.passedElement.element;
      const statusSelect = document.querySelector('form .form-group select#deal_service_status');
      
      // Trigger the change event on page load to set initial state
      toggleStatusField(supplierSelect, statusSelect);

      // Add event listener to supplier select
      supplierSelect.addEventListener(
        'change', 
        function(event) {
          toggleStatusField(this, statusSelect);
      });
      
    } catch (e) {
      console.error("Error setting up supplier integration listener:", e);
    }
  }
  
  async toggleSupplierIntegrationStatusField(target, statusSelect) {
    const parent_target = statusSelect.closest("div.select-inline");
    const status_choice = statusSelect.choices;
    const supplier_choice_value = target.choices.getValue().customProperties;

    if (!status_choice || !supplier_choice_value)  { return; }
    
    const is_integration = supplier_choice_value.transport_integration;

    if ( is_integration) {
      // Disable status dropdown
      status_choice.disable();
      // Set status to 'planned'
      status_choice.setChoiceByValue('planned');
      
      // Update the visual status indicator
      if (parent_target) {
        // Remove all existing status classes
        parent_target.classList.remove("select-default", "select-warning", "select-success", "select-danger", "select-info");
        // Add the 'planned' status class
        parent_target.classList.add("select-default");
      }
    } else {
      statusSelect.disabled = false;
      status_choice.enable();
    }
  }

}
